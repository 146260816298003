import React,{useState} from "react";

const ThComponent = ({title, update,accessor}) => {
    const [name,setName] = useState(title)
    const [edit,setEdit] = useState(false)

    const handler = ({target}) => {
        setName(target.value)
    }

    const editController = () => {
        if(edit){
            update(name, accessor)
        }
        setEdit(prev => !prev)
    }

    return(
        <th onDoubleClick={editController}>
            {
                edit
                    ? <input type="text"
                             value={name}
                             onChange={e => handler(e)}
                             className={'input input__th'}
                             onBlur={editController} autoFocus/>
                    : name
            }
        </th>
    )
}

export default ThComponent