import React from "react";
import {useNode} from '@craftjs/core'
import {Slider} from '@material-ui/core'
import {highlightElement} from './functions'

export const Divider = () => {
    const {connectors:{connect,drag}, isActive, padding} = useNode(node => ({
        padding: node.data.props.padding,
        isActive: node.events.selected
    }))
    return(
        <div style={{padding:`${padding}px 0`, border:'1px dotted #333', outline: `${highlightElement(isActive)}`}} ref={ref => connect(drag(ref))}/>
    )
}

const DividerSettings = () => {
    const { actions: {setProp}, padding } = useNode((node) => ({
        padding: node.data.props.padding
    }));

    return(
        <div>
            <section className={'elements-column'}>
                <h3>Разделитель</h3>
                <button className={'btn btn-primary'} onClick={() => setProp(props => props.padding = 10)}
                >Сбросить</button>
            </section>
            <hr/>
            <section>
                <p>Высота: {padding * 2}px</p>
                <Slider
                    value={padding}
                    min={1}
                    max={100}
                    step={1}
                    onChange={(_, value) => {
                        setProp(props => props.padding = value);
                    }}
                />
            </section>
        </div>
    )
}

Divider.craft = {
    props: {
        padding:10,
    },
    related: {
        settings: DividerSettings
    }
}
