import React, {Fragment} from "react";
import {getColor} from './newsComponents/functions'
import ReactPlayer from "react-player";
import {v4 as uuidV4} from 'uuid'
import {SERVER_HOST} from '../../constants/axiosURLs'
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';

export function newsParser(data) {
    let type = data.type.resolvedName || data.type
    switch (type) {
        case('Container'):
            return createContainerElement(data)
        case('Button'):
            return createButtonElement(data.props)
        case('Divider'):
            return createDividerElement(data.props)
        case('Text'):
            return createTextElement(data)
        case('Table'):
            return createTableElement(data.props)
        case ('Video'):
            return createVideoElement(data.props)
        case ('Image'):
            return createImageElement(data.props)
        case ('Carousel'):
            return createCarouselElement(data.props)
        default:
            return null
    }
}

export function findComponent(data, nodes = []) {
    return nodes.map(node => {
        let item = data[node]
        if (item.nodes.length) {
            let childrenArray = findComponent(data, item.nodes)
            item = {...item, childrenArray}
        }
        return item
    })
}

export function createTextElement(data) {
    const {fontStyle, color, fontSize, tagName, text, textDecoration, margin, textAlign} = data.props || data
    return React.createElement(tagName, {
        style: {
            textDecoration,
            color: getColor(color),
            margin,
            fontSize,
            fontStyle,
            textAlign,
            whiteSpace:'pre'
        }, dangerouslySetInnerHTML: {__html: text}
    })
}

export function createButtonElement(props) {
    const {background, color, text, display, justifyContent, href, margin} = props
    return <div style={{
        display,
        justifyContent
    }}>
        <a href={href} className={'btn'} style={{
            background: getColor(background),
            color: getColor(color),
            margin: `0 ${margin}`
        }}>{text}</a>
    </div>
}

export function createDividerElement(props) {
    const {padding} = props
    return <div style={{padding}}/>
}

export function createVideoElement(props) {
    const {width, height, url, display, justifyContent} = props
    return <div style={{display, justifyContent}}><ReactPlayer width={`${width}%`} height={height} url={url} controls/>
    </div>
}

export function createImageElement(props) {
    let {display, width, file} = props

    return <div style={{display}} key={file.id}>
        <img src={file.serverId ? `${SERVER_HOST}${file.serverId.url}` : "#"} style={{width: `${width}%`}} alt={'image'}/>
    </div>
}

export function createTableElement(props) {
    const {columns, data, justifyContent} = props
    return <div style={{display: 'flex', justifyContent}}>
        <table>
            <thead>
            <tr>
                {columns.map(title => (
                    <th>{title.header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((item, i) => (
                <tr key={i}>
                    {props.columns.map(column => (
                        <Fragment key={column.accessor}>
                            <td>{item[column.accessor]}</td>
                        </Fragment>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}

export function createContainerElement(data) {
    let children = []
    if (data.childrenArray) {
        data.childrenArray.map((item) => {
            let childrenItem = newsParser(item)
            if (childrenItem) {
                children = [...children, childrenItem]
            }
        })
    }

    const {display, padding, background, justifyContent, alignItems, width} = data.props
    return <div style={{
        display,
        padding,
        background: getColor(background),
        justifyContent,
        alignItems,
        width: `${width}%`
    }} key={uuidV4()}>{children}</div>

}

export function createCarouselElement(data) {
    let {files} = data

    const items = files.map((file) => {
        return (
            <div className={'carousel__block-wrapper'}>
                <img className={'carousel__slide-image'} src={file.serverId ? `${SERVER_HOST}${file.serverId.url}` : "#"} alt="image"/>
            </div>
        )
    })

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return <AliceCarousel items={items} responsive={responsive}/>
}