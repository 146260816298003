import React, { Component } from 'react'
import LoginTabset from './loginTabset';
import Slider from 'react-slick';
import authIcon from '../../assets/images/auth-icon.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {connect} from "react-redux";
import {loadUserInfo} from "../../redux/actions/user";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.props.loadUserInfo(false);
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        if (this.props.user) {
            window.location.href = "/dashboard";
            return ""
        } else {
            return (
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        <div className="svg-icon">
                                            <img src={authIcon} className="Img-fluid" alt={"Омегабот"} />
                                        </div>
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3>Добро пожаловать в Админ-панель</h3>
                                                    <p>
                                                        И раз уж нам любезно предоставили слайдер, почему бы
                                                        не рассказать пару интересных фактов...
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3>На вершине технологий</h3>
                                                    <p>Наша панель является инновационной, ведь ее с любовью разработали
                                                        профессионалы высокого уровня.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3>Забота о Вас</h3>
                                                    <p>Мы заботимся о Вашем ментальном здоровье, поэтому в случае возникновения
                                                        проблем с сервисом обратитесь к нашей дружелюбной техподдержке.</p>
                                                </div>
                                            </div>
                                        </Slider >
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <LoginTabset />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect((state) => state, { loadUserInfo }) (Login);
