import React, {Component} from 'react';

export class DeliveryDetail extends Component {
    render() {
        let { detail } = this.props;
        return (
            <div className={'product-component mt'}>
                <h1 className={'product-title-main'}>Информация о доставке</h1>
                <h6 className="product-title">Тип доставки</h6>
                <p>{
                    detail.type === 'courier'
                        ? "Доставка курьером"
                        : `Самовывоз из ${detail.type === 'office' ? 'офиса' :'ПВЗ'}`
                }</p>

                <h6 className="product-title">
                    {detail.type === 'courier' ? "Адрес доставки" : "Пункт выдачи заказа"}
                </h6>
                <p>{ detail.city }, { detail.address }</p>

                {
                    detail.type === 'office'
                        ? null
                        : <>
                            <h6 className="product-title">Срок доставки</h6>
                            <p>{ detail.term } дн.</p>
                        </>
                }
            </div>
        )
    }
}

export default DeliveryDetail