import React from 'react'
import {useNode} from '@craftjs/core'
import {ChromePicker} from 'react-color'
import {Slider} from '@material-ui/core'
import {highlightElement, getColor, checkElementOnActive} from './functions'
import './style.css'

export const Button = () => {

    const {
        connectors: {connect, drag},
        background,
        text,
        color,
        display,
        justifyContent,
        margin,
        isActive
    } = useNode(node => ({
        text: node.data.props.text,
        color: node.data.props.color,
        background: node.data.props.background,
        display: node.data.props.display,
        justifyContent: node.data.props.justifyContent,
        margin: node.data.props.margin,
        isActive: node.events.selected
    }))

    return (
        <div ref={ref => connect(drag(ref))}
             style={{display, justifyContent, outline: `${highlightElement(isActive)}`}}>
            <a
                className={'btn'}
                style={{
                    background: getColor(background),
                    margin: `0 ${margin}px`,
                    color: getColor(color)
                }}
            >{text}</a>
        </div>
    )
}

const ButtonSettings = () => {
    const {actions: {setProp}, text, color, background, display, justifyContent, href, margin} = useNode((node) => ({
        text: node.data.props.text,
        color: node.data.props.color,
        background: node.data.props.background,
        display: node.data.props.display,
        justifyContent: node.data.props.justifyContent,
        href: node.data.props.href,
        margin: node.data.props.margin
    }));

    const textHandler = ({target}) => {
        setProp(props => props.text = target.value)
    }

    const hrefHandler = ({target}) => {
        setProp(props => props.href = target.value)
    }

    return (
        <div>
            <section className={'elements-column'}>
                <h3>Кнопка</h3>
                <button className={'btn btn-primary'} onClick={() => setProp(props => ({
                    text: props.text = 'Кнопка',
                    display: props.display = 'inline-block',
                    justifyContent: props.justifyContent = 'flex-start',
                    background: props.background = {r: 68, g: 183, b: 196, a: 1},
                    color: props.color = {r: 255, g: 255, b: 255, a: 1},
                    href: props.href = '#'
                }))}
                >Сбросить
                </button>
            </section>
            <hr/>
            <section>
                <p>Текст</p>
                <div className={'elements-row'}>
                    <input type="text" className={'form-control'} value={text} onChange={e => textHandler(e)}/>
                </div>
            </section>
            <hr/>
            <section>
                <p>Ссылка</p>
                <div className={'elements-row'}>
                    <input type="text" className={'form-control'} value={href} onChange={e => hrefHandler(e)}/>
                </div>
            </section>
            <hr/>
            <section>
                <p>Display</p>
                <div className={'elements-row'}>
                <span onClick={() => setProp(props => props.display = 'flex')}
                      className={checkElementOnActive(display,'flex')}
                >flex</span>
                    <span onClick={() => setProp(props => props.display = 'inline-block')}
                          className={checkElementOnActive(display, 'inline-block')}
                    >inline block</span>
                </div>
            </section>
            <hr/>
            {
                display === 'flex'
                    ? <>
                        <section>
                            <p>Расположение</p>
                            <div className={'elements-row'}>
                            <span onClick={() => setProp(props => props.justifyContent = 'flex-start')}
                                  className={checkElementOnActive(justifyContent,'flex-start')}
                            >слева</span>
                                <span onClick={() => setProp(props => props.justifyContent = 'center')}
                                      className={checkElementOnActive(justifyContent,'center')}
                                >центр</span>
                                <span onClick={() => setProp(props => props.justifyContent = 'flex-end')}
                                      className={checkElementOnActive(justifyContent, 'flex-end')}
                                >справа</span>
                            </div>
                        </section>
                        <hr/>
                    </>
                    : ''
            }
            <section>
                <p>Горизонтальные отступы: {margin}px</p>
                <Slider
                    value={margin}
                    min={0}
                    step={1}
                    max={30}
                    onChange={(_, value) => setProp(props => props.margin = value)}
                />
            </section>
            <hr/>
            <section>
                <p>Цвет текста</p>
                <div className={'elements-row'}>
                    <ChromePicker color={color} onChange={e => setProp(props => props.color = e.rgb)}/>
                </div>
            </section>
            <hr/>
            <section>
                <p>Цвет фона</p>
                <div className={'elements-row'}>
                    <ChromePicker color={background} onChange={e => setProp(props => props.background = e.rgb)}/>
                </div>
            </section>
        </div>
    )
}

Button.craft = {
    props: {
        text: 'Кнопка',
        display: 'inline-block',
        justifyContent: 'flex-start',
        background: {r: 68, g: 183, b: 196, a: 1},
        color: {r: 255, g: 255, b: 255, a: 1},
        href: '',
        margin: 0
    },
    related: {
        settings: ButtonSettings
    }
}