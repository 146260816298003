import React, {Component} from "react";

import {connect} from "react-redux";
import {loadUserInfo} from "./redux/actions/user";

import Loader from "react-loader-spinner";
import {THEME_COLOR} from "./constants/other";

class SecurityProvider extends Component {
    componentDidMount() {
        this.props.loadUserInfo();
    }

    render() {
        if (this.props.user) {
            return this.props.children;
        } else {
            return <Loader type="Hearts"
                           width={50} height={50}
                           color={THEME_COLOR}
                           className={"obot-loader"} />;
        }
    }
}

export default connect((state) => state, { loadUserInfo }) (SecurityProvider);