import React, { Component,Fragment } from 'react'
import { Search } from 'react-feather';
import {throttle} from "../../../assets/js/functions";
import {search} from "../../../assets/axios/search";

export class SearchHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            request: ''
        }
    }

    changeRequest(value) {
        this.setState({request: value},
            () => {
                search(this.props.url,
                    this.state.request,
                    this.props.callback)
            });
    }

    render() {
        this.changeRequest = throttle(this.changeRequest, 1000);

        return (
            <Fragment>
                <div className={"obot-search"}>
                    <Search />
                    <input className={"form-control-plaintext"}
                           type="search"
                           placeholder="Поиск..."
                           onChange={ (event) => this.changeRequest(event.target.value) } />
                </div>
            </Fragment>
        )
    }
}

export default SearchHeader
