import React, {Component} from 'react';

export class ProductComplectationForm extends Component {
    render() {
        let { complectationItems, onChange, onAdd } = this.props;

        return (
            <div>
                <div className={"text-center"}>
                    <button type="button"
                            className="btn btn-outline-info"
                            onClick={ () => onAdd() }>Добавить</button>
                </div>

                {
                    complectationItems.map((position, index) =>
                        <div className={"obot-equipment-item"} key={index}>
                            <div className="form-group row">
                                <div className={"obot-form-item col-8"}>
                                    <label className="col-form-label pt-0"> Позиция</label>
                                    <input className="form-control"
                                           type="text" value={position.name}
                                           onChange={(event) => {
                                               onChange(index, "name", event.target.value);
                                           }}/>
                                </div>

                                <div className={"obot-form-item col-4"}>
                                    <label className="col-form-label pt-0"> Количество</label>
                                    <input className="form-control"
                                           type="text" value={position.value}
                                           onChange={(event) => {
                                               onChange(index, "value", event.target.value);
                                           }}/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default ProductComplectationForm