import React, {useRef, useState} from 'react'
import {useNode} from '@craftjs/core'
import {ChromePicker} from 'react-color'
import {Slider} from '@material-ui/core'
import {highlightElement, checkElementOnActive, getColor} from './functions'
import './style.css'

export const Text = () => {

    const {
        connectors: {connect, drag},
        isActive,
        actions: {setProp},
        text,
        fontSize,
        tagName,
        color,
        fontWeight,
        fontStyle,
        margin,
        textAlign,
        textDecoration,
        display,
        edit
    } = useNode((node) => ({
        text: node.data.props.text,
        fontSize: node.data.props.fontSize,
        tagName: node.data.props.tagName,
        color: node.data.props.color,
        fontWeight: node.data.props.fontWeight,
        fontStyle: node.data.props.fontStyle,
        margin: node.data.props.margin,
        textAlign: node.data.props.textAlign,
        textDecoration: node.data.props.textDecoration,
        display: node.data.props.display,
        isActive: node.events.selected,
        edit: node.data.props.edit
    }));

    const myRef = useRef(null)
    setProp(props => props.ref = myRef)

    const textareaHandler = ({target}) => {
        setProp(props => props.text = target.value)
    }

    return (
        <div ref={ref => connect(drag(ref))}
             style={{display, outline: `${highlightElement(isActive)}`}}
        >
            {edit ?
                <textarea value={text}
                          onChange={e => textareaHandler(e)}
                          style={{width: '100%', resize: 'none', height: 100, color}}
                          ref={myRef}
                          autoFocus
                /> : React.createElement(tagName, {
                    style: {
                        textDecoration,
                        color: getColor(color),
                        margin,
                        fontSize,
                        fontStyle,
                        textAlign,
                        fontWeight,
                        whiteSpace:'pre'
                    }, dangerouslySetInnerHTML: {__html: text}
                })
            }
        </div>
    )
}

const TextSettings = () => {
    const {
        actions: {setProp},
        fontSize,
        display,
        color,
        textAlign,
        fontWeight,
        fontStyle,
        tagName,
        textDecoration,
        text,
        ref,
        edit
    } = useNode((node) => ({
        fontSize: node.data.props.fontSize,
        display: node.data.props.display,
        color: node.data.props.color,
        textAlign: node.data.props.textAlign,
        fontWeight: node.data.props.fontWeight,
        fontStyle: node.data.props.fontStyle,
        tagName: node.data.props.tagName,
        textDecoration: node.data.props.textDecoration,
        text: node.data.props.text,
        ref: node.data.props.ref,
        edit: node.data.props.edit
    }));
    const [link, setLink] = useState('')

    const linkHandler = ({target}) => {
        setLink(target.value)
    }

    const changeTagName = (tag, size) => {
        let condition = tag === tagName
        setProp(props => ({
            tagName: props.tagName = condition ? 'p' : tag,
            fontSize: props.fontSize = condition ? 16 : size,
            fontWeight: props.fontWeight = condition ? 400 : 700
        }))
    }

    const changeColor = ({rgb}) => {
        setProp(props => props.color = rgb)
    }

    const makeLink = () => {
        let refs = ref.current
        let start = refs.selectionStart
        let end = refs.selectionEnd

        let startSelectedPos
        let endSelectedPos
        if (start > end) {
            endSelectedPos = start
            startSelectedPos = end
        } else {
            endSelectedPos = end
            startSelectedPos = start
        }
        if (startSelectedPos !== endSelectedPos) {
            let startString = text.substr(0, startSelectedPos);
            let middleString = text.substr(startSelectedPos, endSelectedPos - startSelectedPos)
            let myWord =`<a href={'${link}'}>${middleString}</a>`
            let endString = text.substr(endSelectedPos, text.length - endSelectedPos)
            let finalString = `${startString} ${myWord} ${endString}`
            setLink('')
            setProp(props => props.text = finalString)
        }
    }

    return (
        <div>
            <section className={'elements-column'}>
                <h3>Текст</h3>
                <button className={'btn btn-primary'} onClick={() => setProp(props => ({
                    text: props.text = "Текст для примера",
                    fontSize: props.fontSize = 16,
                    tagName: props.tagName = 'p',
                    color: props.color = '#333',
                    fontWeight: props.fontWeight = 400,
                    fontStyle: props.fontStyle = 'normal',
                    margin: props.margin = 0,
                    textAlign: props.textAlign = 'left',
                    edit: props.edit = false,
                    display: props.display = 'block'
                }))}>Сбросить
                </button>
                <button className={'btn'}
                        style={{margin:'10px 0 0 0'}}
                        onClick={() => setProp(props => props.edit = !props.edit)}>{edit ? 'Сохранить' : 'Изменить текст'}</button>
            </section>
            <hr/>
            <section>
                <p>Ссылка</p>
                <div className={'elements-column'}>
                    <input type="text" className={'form-control'} value={link} onChange={e => linkHandler(e)}/>
                    <button onClick={makeLink} className={'btn text-component__button'}>Создать</button>
                </div>
            </section>
            <hr/>
            <section>
                <p>Размер текста: {fontSize}px</p>
                <Slider
                    value={fontSize}
                    min={10}
                    step={1}
                    max={50}
                    onChange={(_, value) => setProp(props => props.fontSize = value)}
                />
            </section>
            <hr/>
            <section>
                <p>Заголовки</p>
                <div className={'elements-row'}>
                    <p onClick={() => changeTagName('h1', 32)}
                       className={checkElementOnActive(tagName, 'h1')}
                    >H1</p>
                    <p onClick={() => changeTagName('h2', 24)}
                       className={checkElementOnActive(tagName, 'h2')}
                    >H2</p>
                    <p onClick={() => changeTagName('h3', 18.72)}
                       className={checkElementOnActive(tagName, 'h3')}
                    >H3</p>
                    <p onClick={() => changeTagName('h4', 16)}
                       className={checkElementOnActive(tagName, 'h4')}
                    >H4</p>
                    <p onClick={() => changeTagName('h5', 13.28)}
                       className={checkElementOnActive(tagName, 'h5')}
                    >H5</p>
                    <p onClick={() => changeTagName('h6', 10.72)}
                       className={checkElementOnActive(tagName, 'h6')}
                    >H6</p>
                </div>
            </section>
            <hr/>
            <section>
                <p>Display</p>
                <div className={'elements-row'}>
                <span onClick={() => setProp(props => props.display = 'block')}
                      className={checkElementOnActive(display, 'block')}
                >block</span>
                    <span onClick={() => setProp(props => props.display = 'inline-block')}
                          className={checkElementOnActive(display, 'inline-block')}
                    >inline block</span>
                </div>
            </section>
            <hr/>
            <section>
                <p>Расположение</p>
                <div className={'elements-row'}>
                        <span onClick={() => setProp(props => props.textAlign = 'left')}
                              className={checkElementOnActive(textAlign, 'left')}
                        >слева</span>
                    <span onClick={() => setProp(props => props.textAlign = 'center')}
                          className={checkElementOnActive(textAlign, 'center')}
                    >центр</span>
                    <span onClick={() => setProp(props => props.textAlign = 'right')}
                          className={checkElementOnActive(textAlign, 'right')}
                    >справа</span>
                </div>
            </section>
            <hr/>
            <section>
                <p>Стиль</p>
                <div className={'elements-row'}>
                    <span onClick={() => setProp(props => props.fontWeight = props.fontWeight === 700 ? 400 : 700)}
                          className={checkElementOnActive(fontWeight, 700)}
                    >B</span>
                    <span
                        onClick={() => setProp(props => props.textDecoration = props.textDecoration === 'none' ? 'underline' : 'none')}
                        className={checkElementOnActive(textDecoration, 'underline')}
                    >U</span>
                    <span
                        onClick={() => setProp(props => props.fontStyle = props.fontStyle === 'italic' ? 'normal' : 'italic')}
                        className={checkElementOnActive(fontStyle, 'italic')}
                    >I</span>
                </div>
            </section>
            <hr/>
            <section>
                <p>Выбрать цвет</p>
                <div className={'elements-row'}>
                    <ChromePicker onChange={e => changeColor(e)} color={color}/>
                </div>
            </section>
        </div>
    )
}

Text.craft = {
    props: {
        text: "Текст для примера",
        fontSize: 16,
        tagName: 'p',
        color: '#333',
        fontWeight: 400,
        fontStyle: 'normal',
        margin: 0,
        textAlign: 'left',
        display: 'block',
        textDecoration: 'none',
        canDragProp: true,
        ref: null,
        edit: false
    },
    rules: {
    },
    related: {
        settings: TextSettings
    }
}