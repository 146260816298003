import React from "react";
import {Link} from "react-router-dom";
import {formatDate} from "../js/functions";
import {Trash2, Edit} from "react-feather";

export const newsColumns = (onDelete, onGetNews) => [
    {
        Header: <b>№ новости</b>,
        accessor: "id",
        Cell: row => <div>
            <Link to={`/pages/page-detail/${row.original.id}`}>
                {row.original.id}
            </Link>
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Название</b>,
        accessor: "title",
        Cell: row => <div>
            {row.original.title}
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Автор</b>,
        accessor: "author",
        Cell: row => <div>
            {row.original.creator.name}
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Дата</b>,
        accessor: "date",
        Cell: row => <div>
            {formatDate(row.original.time)}
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Скрыто</b>,
        accessor: "hidden",
        Cell: row => <div>
            {row.original.hidden ? 'Да' : 'Нет'}
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Действие</b>,
        Cell: row => <div className={"table-toolbar"}>
            <div className={'edit'}>
                <div onClick={ () => onGetNews(row.original.id)}>
                    <Edit/>
                </div>
            </div>
            <div className={"delete"}>
                <div onClick={ () => onDelete(row.original.id)}>
                    <Trash2/>
                </div>
            </div>
        </div>,
        style: {
            textAlign: 'center'
        }
    }
]