import * as types from "../../constants/actionsTypes/orders";
import {getOrdersList} from "../../assets/axios/orders";

function updateOrdersList(list) {
    return {
        type: types.UPDATE_ORDERS_LIST,
        value: list
    };
}

export function loadOrdersList(list = null) {
    if (list) {
        return (dispatch) => {
            dispatch( updateOrdersList(list) );
        }
    } else {
        return (dispatch) => {
            getOrdersList(
                (response) => dispatch( updateOrdersList(response.data) ),
                () => {
                    alert("Не удалось загрузить список заказов");
                    dispatch( updateOrdersList([]) );
                });

        }
    }
}