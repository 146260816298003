import React, {Component, Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';

import {Text} from './newsComponents/Text'
import {Container} from "./newsComponents/Container";
import {Button} from './newsComponents/Button'
import {SettingsPanel} from './newsComponents/SettingsPanel'
import {ToolBar} from './newsComponents/ToolBar'
import {Image} from './newsComponents/Image'
import {Divider} from './newsComponents/Divider'
import {TopBar} from "./newsComponents/TopBar";
import {Video} from './newsComponents/Video'
import {Table} from './newsComponents/Table'
import {Carousel} from "./newsComponents/Carousel";

import {Editor, Frame, Element} from "@craftjs/core";
import {getNewsDetail} from "../../assets/axios/news";
import {connect} from "react-redux";

export const editContext = React.createContext(null)

export class EditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picturesServerArray: [],
            picturesArray: [],
            carouselState: {id: 0, imagesArray: []},
            typeLocation: ''
        }
        this.updateState = this.updateState.bind(this)
        this.setImage = this.setImage.bind(this)
        this.setCarousel = this.setCarousel.bind(this)
        this.removeCarouselImage = this.removeCarouselImage.bind(this)
        this.removeImage = this.removeImage.bind(this)
    }

    componentDidMount() {
        let location = window.location.pathname.split('/')
        let typeLocation = location[2]
        this.setState({typeLocation})

        if (typeLocation === 'edit-page') {
            getNewsDetail(this.props.newsId, this.updateState)
        }
    }

    updateState(value) {
        this.setState({picturesServerArray: value.pictures})
    }

    setImage(file) {
        let foundPicture = this.state.picturesArray.find(item => item.id === file.id)
        if (foundPicture) {
            foundPicture.image = file.image
        } else {
            this.setState({picturesArray: [...this.state.picturesArray, file]})
        }
    }

    setCarousel(id, file) {
        let foundPicture = this.state.carouselState.imagesArray.find(item => item.id === file.id)
        if (foundPicture) {
            foundPicture.image = file.image
        } else {
            this.setState({carouselState: {id, imagesArray: [...this.state.carouselState.imagesArray, file]}})
        }
    }

    removeCarouselImage(id) {
        let stateId = this.state.carouselState.id
        let updatedState = this.state.carouselState.imagesArray.filter(item => item.id !== id)
        this.setState({carouselState: {id: stateId, imagesArray: updatedState}})
    }

    removeImage(id) {
        let updatedState = this.state.picturesArray.filter(item => item.id !== id)
        this.setState({picturesArray: updatedState})
    }

    render() {
        let contextState = {
            setImage: this.setImage,
            setCarousel: this.setCarousel,
            removeCarouselImage: this.removeCarouselImage,
            removeImage: this.removeImage
        }
        return (
            <Fragment>
                <Breadcrumb title={this.state.typeLocation === 'create-page' ? "Создать новость" : "Редактировать новость"} parent="Pages"/>
                <div className="container-fluid elements-row">
                    <editContext.Provider value={contextState}>
                        <Editor resolver={{Text, Button, Container, Image, Divider, Video, Table, Carousel}}>
                            <div className="card" style={{width: '70%'}}>
                                <div className="card-header">
                                    <TopBar images={this.state.picturesArray} carousel={this.state.carouselState}/>
                                </div>
                                <div className="card-body">
                                    <Frame id={'main-div'}>
                                        {
                                            this.state.typeLocation === 'create-page'
                                                ? <Element is={Container} background={'#fff'} canvas/>
                                                : <Element background={'#fff'} canvas/>
                                        }
                                    </Frame>
                                </div>
                            </div>
                            <div style={{width: '28%'}}>
                                <ToolBar/>
                                <SettingsPanel/>
                            </div>
                        </Editor>
                    </editContext.Provider>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        newsId: ownProps.match.params.id,
    }
}

export default connect(mapStateToProps)(EditPage);
