import React, { Component } from 'react'
import {connect} from "react-redux";

export class User_panel extends Component {
    render() {
        return (
            <div>
                <div className="sidebar-user text-center">
                    <h6 className="mt-3 f-14">{this.props.name}</h6>
                    <p>{this.props.email}</p>
                </div>
            </div>
        )
    }
}

let userDefault = {
    name: "Неизвестный пользователь",
    email: "Нет данных"
};

export default connect((state) => state.user ? state.user : userDefault) (User_panel)

