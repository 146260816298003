import React, {useEffect, useState} from "react";
import {useEditor} from '@craftjs/core'
import {createNews, getNews, updateNews, sendImageFile} from "../../../assets/axios/news";
import {processProductPictures} from '../../../assets/axios/process'
import {ArrowLeftCircle, ArrowRightCircle, Save, XCircle} from 'react-feather'
import {Switch} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles';

export const TopBar = ({images, carousel}) => {
    const {actions, query, nodes} = useEditor((state) => ({
        nodes: state.nodes
    }));

    const [title, setTitle] = useState('')
    const [data, setData] = useState(null)
    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        let location = window.location.pathname
        let id = location.split('/')[3]
        if (id) {
            getNews(id, (data) => {
                console.log(data)
                actions.deserialize(data.data.text)
                setTitle(data.data.title)
                setData(data.data)
                setHidden(data.data.hidden)
            })
        }
    }, [])

    const titleHandler = ({target}) => {
        setTitle(target.value)
    }

    const hiddenHandler = ({target}) => {
        setHidden(target.checked)
    }

    const deleteAll = () => {
        //eslint-disable-next-line no-restricted-globals
        let confirmed = confirm('Очистить констуктор?')
        if (confirmed) {
            let node = nodes['ROOT'].data.nodes
            node.forEach((item) => {
                actions.delete(item)
            })
        }
    }

    const getPicturesIds = (currentArray) => {
        return new Promise(resolve => {
            let picturesArray = []

            currentArray.forEach(item => {
                picturesArray.push(item.image)
            })

            let formData = processProductPictures(picturesArray)
            sendImageFile(formData, data => resolve(data.data))
        })
    }

    const updatePicturesValue = (currentArray, value) => {
        let picturesIds = []
        let myValue = JSON.parse(value)

        currentArray.forEach((picture, i) => {
            picturesIds.push(picture.id)
            myValue[images[i].id].props.file.serverId = picture
        })
        return {value: myValue, array: picturesIds}
    }

    const updateCarouselValue = (currentArray, value) => {
        let picturesIds = []
        let myValue = JSON.parse(value)

        currentArray.forEach((picture, i) => {
            let count = i
            picturesIds.push(picture.id)
            while (myValue[carousel.id].props.files[count].serverId) {
                count++
            }
            myValue[carousel.id].props.files[count].serverId = picture
        })
        return {value: myValue, array: picturesIds}
    }

    const saveNews = async (value) => {
        if (!title) return
        let imageValue
        let carouselValue

        if (images.length) {
            let picturesArray = await getPicturesIds(images)
            imageValue = updatePicturesValue(picturesArray, value)
        }
        if (carousel.imagesArray.length) {
            let carouselArray = await getPicturesIds(carousel.imagesArray)
            let test = imageValue ? JSON.stringify(imageValue.value) : value
            carouselValue = updateCarouselValue(carouselArray, test)
        }

        let arrayForValue = imageValue && carouselValue
            ? [...imageValue.array, ...carouselValue.array]
            : imageValue
                ? [...imageValue.array]
                : carouselValue
                    ? [...carouselValue.array]
                    : []

        let stateForValue = imageValue && carouselValue
            ? JSON.stringify(carouselValue.value)
            : imageValue
                ? JSON.stringify(imageValue.value)
                : carouselValue
                    ? JSON.stringify(carouselValue.value)
                    : value

        let newValue = {value: stateForValue, array: arrayForValue}

        if (data) {
            let updatedNews = {
                title,
                time: data.time,
                text: newValue.value,
                hidden,
                pictures: newValue.array
            }
            updateNews(data.id, updatedNews, () => relocationFunc('Updated successfully'))
        } else {
            let time = new Date()
            let newNews = {title, time, text: newValue.value, hidden, pictures: newValue.array}
            createNews(newNews, () => relocationFunc('Created successfully'))
        }
    }

    const relocationFunc = (text) => {
        alert(text)
        window.location.href = `/pages/list-page`
    }

    const CustomSwitch = withStyles({
        switchBase: {
            color: 'gray',
            '&$checked': {
                color: '#44B7C4',
            },
            '&$checked + $track': {
                backgroundColor: '#44B7C4',
            },
        },
        checked: {},
        track: {},
    })(Switch);

    return (
        <div>
            <div className={'topBar__buttons-wrapper'}>
                <div className={'topBar__left-side'}>
                    <ArrowLeftCircle onClick={() => actions.history.undo()}/>
                    <ArrowRightCircle onClick={() => actions.history.redo()}/>
                    <CustomSwitch checked={hidden} onChange={hiddenHandler}/>
                    <Save onClick={() => saveNews(query.serialize())}/>
                </div>
                <XCircle onClick={deleteAll} title="clean"/>
            </div>
            <div className={'topBar-component__text-field'}>
                <input type="text"
                       className={'form-control'}
                       placeholder={'Введите заголовок'}
                       value={title}
                       onChange={e => titleHandler(e)}/>
            </div>
        </div>
    )
}