import React from 'react';

import {formatDate, shortenString} from "../js/functions";
import {SYMBOL} from "../../constants/other";
import {Trash2} from "react-feather";
import {Link} from "react-router-dom";

export const orderColumns = (onDelete) => [
    {
        Header: <b>№ заказа</b>,
        accessor: "id",
        Cell: row => <div>
            <Link to={`/orders/order-detail/${row.original.id}`}>
            {row.original.new
                ? <span className={"order-new-mark"}>NEW</span>
                : <></>} {row.original.id}
            </Link>
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Статус</b>,
        accessor: "status",
        Cell: row => <div className={"order-status-mark"}
                          style={ {background: row.original.status.color} }>
            {row.original.status.name}
        </div>,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Дата</b>,
        accessor: "time",
        Cell: row => formatDate(row.original.time),
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Сумма</b>,
        accessor: "cost",
        Cell: row => `${row.original.cost} ${SYMBOL}`,
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Комментарий</b>,
        accessor: "comment",
        Cell: row => shortenString(row.original.comment, 20),
        style: {
            textAlign: 'center'
        }
    },
    {
        Header: <b>Действие</b>,
        accessor: "action",
        Cell: row => <div className={"table-toolbar"}>
            <div className={"delete"}>
                <div onClick={ () => onDelete(row.original.id) }>
                    <Trash2/></div>
            </div>
        </div>,
        style: {
            textAlign: 'center'
        }
    }
];

const data = [
    {
        "id": 1,
        "time": "2020-05-27T14:37:06+03:00",
        "cost": 90909,
        "status": {
            "id": 1,
            "name": "Оплачен",
            "description": "Ы",
            "color": "#b3ff00"
        },
        "delivery": {
            "id": 1,
            "term": 2,
            "city": "Санкт-Петербург",
            "cost": 666,
            "PVZ": "Улица Пушкина дом Колотушкина"
        },
        "user": {
            "id": 1,
            "email": "kolebn@kolebn.kolebn",
            "name": "Колян колянович Колян",
            "phone": "+9876542213"
        },
        "orderItems": [
            {
                "id": 1,
                "count": 15,
                "product": {
                    "id": 1,
                    "name": "Новый продукт",
                    "price": 22220,
                    "discount": null,
                    "shortDetails": "Очень хороший робот",
                    "description": "",
                    "stock": 2,
                    "new": false,
                    "length": 10,
                    "width": 20,
                    "height": 24,
                    "weight": 5,
                    "category": null,
                    "pictures": [],
                    "complectationItems": [
                        {
                            "name": "Робот",
                            "value": "1 шт"
                        }
                    ],
                    "videoLink": "https://vk.com/im?peers=c234&sel=117913616"
                }
            },
            {
                "id": 2,
                "count": 7,
                "product": {
                    "id": 1,
                    "name": "Новый продукт",
                    "price": 22220,
                    "discount": null,
                    "shortDetails": "Очень хороший робот",
                    "description": "",
                    "stock": 2,
                    "new": false,
                    "length": 10,
                    "width": 20,
                    "height": 24,
                    "weight": 5,
                    "category": null,
                    "pictures": [],
                    "complectationItems": [
                        {
                            "name": "Робот",
                            "value": "1 шт"
                        }
                    ],
                    "videoLink": "https://vk.com/im?peers=c234&sel=117913616"
                }
            }
        ],
        "comment": "kek",
        "new": false
    },
    {
        "id": 2,
        "time": "2020-05-27T14:37:06+03:00",
        "cost": 777,
        "status": {
            "id": 1,
            "name": "Не оплачен",
            "description": "Ы",
            "color": "#f00"
        },
        "delivery": {
            "id": 1,
            "term": 2,
            "city": "Санкт-Петербург",
            "cost": 666,
            "PVZ": "Улица Пушкина дом Колотушкина"
        },
        "user": {
            "id": 1,
            "email": "kolebn@kolebn.kolebn",
            "name": "Колян колянович Колян",
            "phone": "+9876542213"
        },
        "orderItems": [
            {
                "id": 1,
                "count": 15,
                "product": {
                    "id": 1,
                    "name": "Новый продукт",
                    "price": 22220,
                    "discount": null,
                    "shortDetails": "Очень хороший робот",
                    "description": "",
                    "stock": 2,
                    "new": false,
                    "length": 10,
                    "width": 20,
                    "height": 24,
                    "weight": 5,
                    "category": null,
                    "pictures": [],
                    "complectationItems": [
                        {
                            "name": "Робот",
                            "value": "1 шт"
                        }
                    ],
                    "videoLink": "https://vk.com/im?peers=c234&sel=117913616"
                }
            }
        ],
        "comment": "kekes",
        "new": true
    },
]

export default data;

export const statuses = [
    {
        id: 1,
        name: "оплачен",
        color: "#9db8d5"
    },
    {
        id: 2,
        name: "не оплачен",
        color: "#e37c7d"
    },
    {
        id: 3,
        name: "отправлен",
        color: "#77bf6d"
    },
    {
        id: 4,
        name: "доставлен",
        color: "#4b854b"
    },
    {
        id: 5,
        name: "отменен",
        color: "#b3b3b3"
    }
];