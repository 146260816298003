import React from "react";
import {useNode} from "@craftjs/core";
import ReactPlayer from 'react-player'
import {Slider} from '@material-ui/core'
import {checkElementOnActive} from "./functions";

export const Video = () => {
    const {connectors: {connect, drag}, width, height, url, display,justifyContent} = useNode(node => ({
        width: node.data.props.width,
        height: node.data.props.height,
        display: node.data.props.display,
        justifyContent: node.data.props.justifyContent,
        url: node.data.props.url
    }))

    return (
        <div ref={ref => connect(drag(ref))} style={{display, justifyContent}}>
            {
                url
                    ? <ReactPlayer url={url} controls width={`${width}%`} height={height}
                                   className={'video-component__player'}/>
                    : <div style={{width: `${width}%`, height}} className={'video-component__plug'}>Добавьте ссылку на
                        видео в настройках</div>
            }
        </div>
    )
}

const VideoSettings = () => {
    const {actions: {setProp}, url, width, height,justifyContent} = useNode(node => ({
        url: node.data.props.url,
        width: node.data.props.width,
        height: node.data.props.height,
        justifyContent: node.data.props.justifyContent
    }))

    const linkHandler = ({target}) => {
        setProp(props => props.url = target.value)
    }
    return (
        <div>
            <section className={'elements-column'}>
                <h3>Видео</h3>
                <p>Ссылка</p>
                <input type="text" className={'form-control'} value={url} onChange={e => linkHandler(e)}/>
                <button className={'btn btn-primary video-component__button'}
                        onClick={() => setProp(props => props.url = '')}
                >Сбросить
                </button>
            </section>
            <hr/>
            <section>
                <p>Ширина {width}%</p>
                <Slider
                    min={1}
                    max={100}
                    step={1}
                    value={width}
                    onChange={(_, value) => {
                        setProp(props => props.width = value)
                    }}
                />
            </section>
            <hr/>
            <section>
                <p>Высота {height}px</p>
                <Slider
                    min={1}
                    max={500}
                    step={1}
                    value={height}
                    onChange={(_, value) => {
                        setProp(props => props.height = value)
                    }}
                />
            </section>
            <hr/>
            <section>
                <p>Расположение</p>
                <div className={'elements-row'}>
                        <span onClick={() => setProp(props => props.justifyContent = 'flex-start')}
                              className={checkElementOnActive(justifyContent, 'flex-start')}
                        >слева</span>
                    <span onClick={() => setProp(props => props.justifyContent = 'center')}
                          className={checkElementOnActive(justifyContent, 'center')}
                    >центр</span>
                    <span onClick={() => setProp(props => props.justifyContent = 'flex-end')}
                          className={checkElementOnActive(justifyContent, 'flex-end')}
                    >справа</span>
                </div>
            </section>
        </div>
    )
}

Video.craft = {
    props: {
        width: 100,
        height: 360,
        display: 'flex',
        justifyContent: 'flex-start',
        url: '',
    },
    related: {
        settings: VideoSettings
    }
}