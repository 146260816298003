import * as types from "../../constants/actionsTypes/user";
import {getUserInfo} from "../../assets/axios/auth";

function updateUserInfo(info) {
    return {
        type: types.UPDATE_USER_INFO,
        value: info
    };
}

export function loadUserInfo(redirect = true) {
    return (dispatch) => {
        getUserInfo(
            (response) => {dispatch( updateUserInfo(response.data) )},
            () => {
                if (redirect) window.location.href = "/" ;
            });
    }
}