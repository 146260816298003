import React, {Component, Fragment} from "react";
import {getNewsDetail} from "../../assets/axios/news";
import {connect} from "react-redux";
import {findComponent, newsParser} from './parser'
import Breadcrumb from "../common/breadcrumb";

class Page_detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [],
            newsNumber: 0
        }

        this.updateState = this.updateState.bind(this)
    }

    componentDidMount() {
        getNewsDetail(this.props.newsId, this.updateState)
    }

    updateState(value) {
        let data = JSON.parse(value.text)
        const nodes = data['ROOT'].nodes
        this.setState({newsNumber: value.id})

        let foundedComponents = findComponent(data, nodes)
        foundedComponents.forEach(item => {
            this.setState({
                components: [...this.state.components, newsParser(item)]
            })
        })
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title={`Новость №${this.state.newsNumber}`} parent="Pages"/>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            {this.state.components.map((item, index) => (
                                <Fragment key={index}>
                                    {item}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        newsId: ownProps.match.params.id,
    }
}

export default connect(mapStateToProps)(Page_detail);