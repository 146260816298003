import React, {useCallback, useContext} from "react";
import {useDropzone} from "react-dropzone";
import {useNode} from "@craftjs/core";
import {Slider} from "@material-ui/core";
import {highlightElement, checkElementOnActive} from "./functions";
import {editContext} from "../EditPage";
import {SERVER_HOST} from "../../../constants/axiosURLs";

export const Image = () => {
    const {connectors: {connect, drag}, width, file, display, isActive} = useNode((node) => ({
        width: node.data.props.width,
        file: node.data.props.file,
        display: node.data.props.display,
        isActive: node.events.selected
    }));
    let path = file ? file.serverId ? `${SERVER_HOST}${file.serverId.url}` : URL.createObjectURL(file.image) : ''
    return (
        <div ref={ref => connect(drag(ref))} style={{display,outline:`${highlightElement(isActive)}`}}>
            <img src={path} height={'auto'} width={`${width}%`} alt={'img'} style={file ? {} : {display:'none'}}/>
            {!file && <div style={{width: `${width}%`}} className={'image-component__plug'}>Загрузите файл в панеле настроек</div>}
        </div>
    )
}

const ImageSettings = () => {
    const {actions: {setProp}, file, name, width, display, id} = useNode((node) => ({
        file: node.data.props.file,
        name: node.data.props.name,
        width: node.data.props.width,
        display: node.data.props.display,
        id: node.data.props.id
    }));

    const editorContext = useContext(editContext)

    const onDrop = useCallback(acceptedFiles => {
        setProp(props => props.file = {id, image: acceptedFiles[0]})
        setProp(props => props.name = acceptedFiles[0].name)
        editorContext.setImage({id, image: acceptedFiles[0]})
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <div>
            <section className={'elements-row'}>
                <h3>Картинка</h3>
            </section>
            <hr/>
            <section>
                <p>Имя файла: <span className={'image__name'}>{name}</span></p>
            </section>
            <hr/>
            <section>
                <p>Ширина: {width}%</p>
                <Slider
                    min={5}
                    max={100}
                    step={1}
                    value={width}
                    onChange={(_, value) => setProp(props => props.width = value)}
                />
            </section>
            <hr/>
            <section>
                <p>Display</p>
                <div className={'elements-row'}>
                    <span onClick={() => setProp(props => props.display = 'block')}
                          className={checkElementOnActive(display, 'block')}
                    >block</span>
                    <span onClick={() => setProp(props => props.display = 'inline-block')}
                          className={checkElementOnActive(display,'inline-block')}
                    >inline-block</span>
                </div>
            </section>
            <hr/>
            <div {...getRootProps()} className={'image-component__dropzone'}>
                <input {...getInputProps()} />
                <p className={'image-component__dropzone-title'}>{file ? 'Изменить' : 'Загрузить'}</p>
            </div>
        </div>
    )
}

Image.craft = {
    props: {
        name: '',
        width: 50,
        file: null,
        display:'block'
    },
    related: {
        settings: ImageSettings
    }
}
