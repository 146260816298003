import React, {useCallback, Fragment, useContext} from "react";
import {useNode} from '@craftjs/core'
import {highlightElement} from "./functions";
import {useDropzone} from "react-dropzone";
import {editContext} from '../EditPage'
import {v4 as uuidV4} from 'uuid'
import {SERVER_HOST} from "../../../constants/axiosURLs";
import {Trash2 as Trash} from 'react-feather'
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';

export const Carousel = () => {
    const {connectors: {connect, drag}, isActive, files} = useNode(node => ({
        isActive: node.events.selected,
        files: node.data.props.files
    }))
    console.log(files)
    const items = files.map((file) => {
        let path = file ? file.serverId ? `${SERVER_HOST}${file.serverId.url}` : URL.createObjectURL(file.image) : ''
        return (
            <div className={'carousel__block-wrapper'}>
                <img className={'carousel__slide-image'} src={path} alt="image"/>
            </div>
        )
    })

    const defaultItem = [
        <div className={'carousel__slide-image'}/>
    ]

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return (
        <div className={'carousel-wrapper'} ref={ref => connect(drag(ref))}
             style={{outline: `${highlightElement(isActive)}`}}>
            <AliceCarousel
                mouseTracking
                items={items.length ? items : defaultItem}
                responsive={responsive}
            />
        </div>
    )
}

const CarouselSettings = () => {
    const {actions: {setProp}, files, id} = useNode(node => ({
        files: node.data.props.files,
        id: node.data.props.id
    }))

    const editorContext = useContext(editContext)

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            let myId = uuidV4()
            setProp(props => props.files = [...props.files, {id: myId, image: file}])
            editorContext.setCarousel(id, {id: myId, image: file})
        })
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const deleteImage = (id) => {
        const updatedFiles = files.filter(file => file.id !== id)
        setProp(props => props.files = updatedFiles)
    }

    return (
        <div>
            <section className={'elements-column'}>
                <h3>Карусель</h3>
            </section>
            <hr/>
            <section className={'elements-column'}>
                {files.length
                    ? files.map(file => {
                        let path = file
                            ? file.serverId
                                ? `${SERVER_HOST}${file.serverId.url}`
                                : URL.createObjectURL(file.image) : ''
                        return (
                            <Fragment key={file.id}>
                                <div className={'carousel__settings-image-block'}>
                                    <img src={path} alt="" style={{height: 70}}/>
                                    <Trash onClick={() => deleteImage(file.id)} style={{cursor: 'pointer'}}/>
                                </div>
                                <hr/>
                            </Fragment>

                        )
                    }) : null
                }
            </section>
            <div {...getRootProps()} className={'image-component__dropzone'}>
                <input {...getInputProps()} />
                <p className={'image-component__dropzone-title'}>Загрузить</p>
            </div>
        </div>
    )
}


Carousel.craft = {
    props: {
        files: []
    },
    related: {
        settings: CarouselSettings
    }
}