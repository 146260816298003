import React, {useContext} from 'react'
import {useEditor} from '@craftjs/core'
import {editContext} from '../EditPage'

export const SettingsPanel = () => {
    const editorContext = useContext(editContext)
    const {selected, actions} = useEditor((state, query) => {
        const currentNodeId = state.events.selected;
        let selected;

        if (currentNodeId) {
            selected = {
                id: currentNodeId,
                name: state.nodes[currentNodeId].data.name,
                settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
                isDeletable: query.node(currentNodeId).isDeletable()
            };
        }
        return {
            selected
        }
    });

    return selected ? (
        <div className={'card settings-panel-component__wrapper'}>
            {
                selected.settings && React.createElement(selected.settings)
            }
            {
                selected.isDeletable ? (
                    <>
                        <hr/>
                        <button
                            className={'btn btn-warning settings-panel-component__button'}
                            onClick={() => {
                                actions.delete(selected.id);
                                editorContext.removeImage(selected.id)
                                // if(myContext) {
                                //     myContext.removeImage(selected.id)
                                // } else  {
                                //     editorContext.removeImage(selected.id)
                                // }
                            }}
                        >Удалить</button>
                    </>
                ) : null
            }
        </div>
    ) : null
}