import axios from "axios";
import {NEWS_LIST, NEWS_DETAIL, NEWS_IMAGE} from "../../constants/axiosURLs";

export function getNewsList(thenFunc,catchFunc) {
    axios.get(NEWS_LIST, {withCredentials:true})
        .then(thenFunc)
        .catch(catchFunc)
}

export function deleteNews(id,thenFunc) {
    //eslint-disable-next-line no-restricted-globals
    let isDelete = confirm("Вы уверены, что хотите удалить новость?");

    if(isDelete) {
        let catchFunc = () =>
            alert("Не удалось удалить новость");

        axios.delete(NEWS_DETAIL(id), {withCredentials:true})
            .then(thenFunc)
            .catch(catchFunc)
    }
}

export function getNewsDetail(id, thenFunc) {
    axios.get(NEWS_DETAIL(id), {withCredentials:true})
        .then(data => thenFunc(data.data))
}

export function createNews(data,thenFunc) {
    axios.post(NEWS_LIST, data, {withCredentials:true})
        .then(thenFunc)
        .catch(() => console.log('error with create'))
}

export function getNews(id,thenFunc) {
    axios.get(NEWS_DETAIL(id), {withCredentials:true})
        .then(thenFunc)
}

export function updateNews(id,data,thenFunc) {
    axios.put(NEWS_DETAIL(id),data,{withCredentials:true})
        .then(thenFunc)
}

export function sendImageFile(data,thenFunc) {
    axios.post(NEWS_IMAGE, data, {withCredentials:true})
        .then(thenFunc)
}