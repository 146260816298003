export function highlightElement(active) {
    return active ? '1px dotted red' : ''
}

export function checkElementOnActive(element, value){
    return element === value ? 'element element_active' : 'element'
}

export function getColor(colorProps) {
    let color
    if (typeof (colorProps) === 'object') {
        color = `rgba(${colorProps.r}, ${colorProps.g}, ${colorProps.b},${colorProps.a})`
    } else {
        color = colorProps
    }
    return color
}