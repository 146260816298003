import React from 'react';

export const ComplectationTable = ({ complectationItems }) => {
    return (
        <table className="table table-striped mb-0">
            <tbody>
            {
                complectationItems.map((item, i) =>
                    <tr key={i}>
                        <th>{ item.name }</th>
                        <td>{ item.value }</td>
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}

export const ProductVideo = ({ link }) => {
    return (
        <div className="mt-4 text-center">
            <div className="embed-responsive embed-responsive-16by9">
                <iframe
                    src={link}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    )
}

export function makeProductTabs(detail) {
    let tabs =  [];

    if (detail.fullDetails && detail.fullDetails !== '<p><br></p>') {
        tabs[tabs.length] = {
            name: "Описание",
            content: <div className={"product-full-details"}
                          dangerouslySetInnerHTML={{ __html: detail.fullDetails }}></div>
        }
    }

    if (detail.characteristicItems && detail.characteristicItems.length) {
        tabs[tabs.length] = {
            name: "Характеристики",
            content: <ComplectationTable complectationItems={detail.characteristicItems || []}/>
        };
    }

    if (detail.complectationItems && detail.complectationItems.length) {
        tabs[tabs.length] = {
            name: "Комплектация",
            content: <ComplectationTable complectationItems={detail.complectationItems || []}/>
        };
    }

    if (detail.videoLink) {
        tabs[tabs.length] = {
            name: "Видео",
            content: <ProductVideo link={detail.videoLink.replace("watch?v=", "embed/")} />
        };
    }

    return tabs;
}