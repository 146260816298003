import React, {Component} from 'react';
import {makeFileIcon, shortenString} from "../../../assets/js/functions";

class ProductFiles extends Component {
    render() {
    let { files } = this.props;
        return (
            <>
                <p className={"product-files-container"}>
                    {files.length !== 0
                      ? <h6 className="product-title">Дополнительные файлы</h6>
                      : ''
                    }
                    <div className={"file"}>
                        {
                            files.map((file) =>
                                <div className={"obot file-preview-container"} key={file.id}>
                                    <div className={"file-name"} onClick={() => window.open(file.url)}>
                                        <img src={makeFileIcon(file.type)}
                                             className={"file-icon"}
                                             alt={file.name}/>
                                        <span title={file.name}>{shortenString(file.name, 25)}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </p>
            </>
        )
    }
}

export default ProductFiles