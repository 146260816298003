import React from 'react'
import {useNode} from '@craftjs/core'
import CustomTable from "../table/CustomTable";
import {highlightElement, checkElementOnActive} from "./functions";

export const Table = () => {
    const {connectors:{connect,drag},setProp, data,columns, justifyContent,display, isActive} = useNode(node => ({
        data: node.data.props.data,
        columns: node.data.props.columns,
        display: node.data.props.display,
        justifyContent: node.data.props.justifyContent,
        isActive: node.events.selected
    }))

    return(
        <div ref={ref => connect(drag(ref))}
             style={{display, justifyContent, outline:`${highlightElement(isActive)}`}}
             className={'table-component__wrapper'}>
            <CustomTable updateState={setProp} data={data} columns={columns}/>
        </div>
    )
}

const TableSettings = () => {
    const {setProp, justifyContent} = useNode(node => ({
        justifyContent: node.data.props.justifyContent
    }))
    return(
        <div>
            <section className={'elements-column'}>
                <h3>Таблица</h3>
            </section>
            <hr/>
            <section>
                <p>Расположение</p>
                <div className={'elements-row'}>
                    <span onClick={() => setProp(props => props.justifyContent = 'flex-start')}
                          className={checkElementOnActive(justifyContent, 'flex-start')}
                    >слева</span>
                    <span onClick={() => setProp(props => props.justifyContent = 'center')}
                          className={checkElementOnActive(justifyContent,'center')}
                    >центр</span>
                    <span onClick={() => setProp(props => props.justifyContent = 'flex-end')}
                          className={checkElementOnActive(justifyContent,'flex-end')}
                    >справа</span>
                </div>
            </section>
        </div>
    )
}

Table.craft = {
    props: {
        data:[
            {
                col1: '',
                col2: '',
            },
            {
                col1: '',
                col2: ''
            }
        ],
        columns:[
            {
                header: 'Column 1',
                accessor: 'col1'
            },
            {
                header: 'Column 2',
                accessor: 'col2'
            }
        ],
        display:'flex',
        justifyContent:'flex-start'
    },
    related: {
        settings: TableSettings
    }
}