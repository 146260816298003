import axios from "axios";
import {CATEGORIES, CATEGORY} from "../../constants/axiosURLs";
import { data } from "../data/categories";

export function getCategoriesList(thenFunc, catchFunc) {
    // let fakeResponse = {data: data};
    // thenFunc(fakeResponse);

    axios.get(CATEGORIES, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function addCategory(data, thenFunc) {
    let catchFunc = () =>
        alert("Не удалось добавить категорию");

    axios.post(CATEGORIES, data, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function editCategory(categoryId, data, thenFunc) {
    let catchFunc = () =>
        alert("Не удалось сохранить изменения");

    axios.put(CATEGORY(categoryId), data, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function deleteCategory(categoryId, thenFunc) {
    // eslint-disable-next-line no-restricted-globals
    let isDelete = confirm("Вы уверены, что хотите удалить категорию?");

    if (isDelete) {
        let catchFunc = () =>
            alert("Не удалось удалить категорию");

        axios.delete(CATEGORY(categoryId), { withCredentials: true })
            .then(thenFunc)
            .catch(catchFunc);
    }
}