import React from 'react'
import {useNode} from '@craftjs/core'
import {ChromePicker} from 'react-color'
import {Slider} from '@material-ui/core'
import {highlightElement, checkElementOnActive, getColor} from './functions'
import './style.css'

export const Container = ({children}) => {
    const {
        connectors: {connect, drag},
        isActive,
        background,
        padding,
        display,
        justifyContent,
        width,
        alignItems
    } = useNode(node => ({
        background: node.data.props.background,
        padding: node.data.props.padding,
        display: node.data.props.display,
        justifyContent: node.data.props.justifyContent,
        width: node.data.props.width,
        alignItems: node.data.props.alignItems,
        isActive: node.events.selected
    }))

    return (
        <div
            ref={ref => connect(drag(ref))}
            style={{
                outline: `${highlightElement(isActive)}`,
                width: `${width}%`,
                background: getColor(background),
                padding,
                display,
                justifyContent,
                alignItems
            }}
        >
            {children}
        </div>
    )
}

const ContainerSettings = () => {
    const {actions: {setProp}, background, display, padding, width, justifyContent, alignItems} = useNode((node) => ({
        background: node.data.props.background,
        display: node.data.props.display,
        padding: node.data.props.padding,
        width: node.data.props.width,
        justifyContent: node.data.props.justifyContent,
        alignItems: node.data.props.alignItems,
    }));

    return (
        <div>
            <section className={'elements-column'}>
                <h3>Контейнер</h3>
                <button className={'btn btn-primary'} onClick={() => setProp(props => ({
                    background: props.background = {r: 238, g: 238, b: 238, a: 1},
                    display: props.display = 'block',
                    padding: props.padding = 30,
                    width: props.width = 100
                }))}
                >Сбросить
                </button>
            </section>
            <hr/>
            <section>
                <p>Внутренние отступы {padding * 2}px</p>
                <Slider
                    min={0}
                    max={50}
                    step={1}
                    value={padding}
                    onChange={(_, value) => {
                        setProp(props => props.padding = value)
                    }}
                />
            </section>
            <hr/>
            <section>
                <p>Ширина {width}%</p>
                <Slider
                    min={0}
                    max={100}
                    step={1}
                    value={width}
                    onChange={(_, value) => setProp(props => props.width = value)}
                />
            </section>
            <hr/>
            <section>
                <p>Display</p>
                <div className={'elements-row'}>
                    <span onClick={() => setProp(props => props.display = 'block')}
                          className={checkElementOnActive(display, 'block')}
                    >block</span>
                    <span onClick={() => setProp(props => props.display = 'inline-block')}
                          className={checkElementOnActive(display, 'inline-block')}
                    >inline</span>
                    <span onClick={() => setProp(props => props.display = 'flex')}
                          className={checkElementOnActive(display, 'flex')}
                    >flex</span>
                </div>
            </section>
            <hr/>
            {display === 'flex'
                ? <>
                    <section className={'elements-row'}>
                        <p>Горизонт.</p>
                        <p>Вертикал.</p>
                    </section>
                    <section className={'elements-row'}>
                        <div className={'container-component__display_start'}>
                            <span onClick={() => setProp(props => props.justifyContent = 'flex-start')}
                                  className={checkElementOnActive(justifyContent, 'flex-start')}
                            >слева</span>
                            <span onClick={() => setProp(props => props.justifyContent = 'center')}
                                  className={checkElementOnActive(justifyContent, 'center')}
                            >центр</span>
                            <span onClick={() => setProp(props => props.justifyContent = 'space-between')}
                                  className={checkElementOnActive(justifyContent, 'space-between')}
                            >между</span>
                            <span onClick={() => setProp(props => props.justifyContent = 'space-around')}
                                  className={checkElementOnActive(justifyContent, 'space-around')}
                            >вокруг</span>
                            <span onClick={() => setProp(props => props.justifyContent = 'flex-end')}
                                  className={checkElementOnActive(justifyContent, 'flex-end')}
                            >справа</span>
                        </div>
                        <div className={'container-component__display_end'}>
                            <span onClick={() => setProp(props => props.alignItems = 'center')}
                                  className={checkElementOnActive(alignItems, 'center')}
                            >центр</span>
                            <span onClick={() => setProp(props => props.alignItems = 'flex-start')}
                                  className={checkElementOnActive(alignItems, 'flex-start')}
                            >слева</span>
                            <span onClick={() => setProp(props => props.alignItems = 'flex-end')}
                                  className={checkElementOnActive(alignItems, 'flex-end')}
                            >справа</span>
                        </div>
                    </section>
                    <hr/>
                </>
                : ''
            }
            <section>
                <p>Цвет фона</p>
                <div className={'elements-row'}>
                    <ChromePicker color={background} onChange={e => setProp(props => props.background = e.rgb)}/>
                </div>
            </section>
        </div>
    )
}

Container.craft = {
    props: {
        background: {r: 238, g: 238, b: 238, a: 1},
        padding: 20,
        display: 'block',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: 100
    },
    related: {
        settings: ContainerSettings
    }
}