import React, {Component} from 'react';
import {Trash2} from "react-feather";
import {makeFileIcon, shortenString} from "../../../assets/js/functions";

class ExistingProductFiles extends Component {
    render() {
        let { files, onDelete } = this.props;

        console.log("files");
        console.log(files);

        return (
            files.length
                ? <div className={"existing-pictures-container file"}>
                    {
                        files.map((file) =>
                            <div className={"obot file-preview-container"} key={file.id}>
                                <div className={"file-name"} onClick={() => window.open(file.url)}>
                                    <img src={makeFileIcon(file.type)}
                                         className={"file-icon"}
                                         alt={file.name}/>
                                    <span>{shortenString(file.name, 25)}</span>
                                </div>

                                <div className={"file-state"}>
                                    <div className={"delete-button"}
                                         onClick={() => onDelete(file)}><Trash2/></div>
                                </div>
                            </div>
                        )
                    }
                </div>
                : ""
        )
    }
}

export default ExistingProductFiles