import React, {Component} from 'react'

class Buttons extends Component {
  render() {
    let {editId, item, updateComment, changeComment, deleteComment, notChangeComment} = this.props
    return (
      <div className={'comment-btn-wrapper'}>
        {
          editId === item.id
            ? <button className={'comment-btn change-comment-btn change-comment-btn_submit'}
                      onClick={() => updateComment(item.id)}>ok</button>
            : <button onClick={() => changeComment(item.id, item.text)}
                      className={'comment-btn comment-btn_change'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor"
                   strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                   className="comment-icon comment-icon_change">
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
              </svg>
            </button>
        }
        {
          editId === item.id
            ? <button className={'comment-btn change-comment-btn change-comment-btn_abort'}
                      onClick={notChangeComment}>no</button>
            : <button onClick={() => deleteComment(item.id)}
                      className={'comment-btn'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                   className="comment-icon">
                <polyline points="3 6 5 6 21 6"/>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                <line x1="10" y1="11" x2="10" y2="17"/>
                <line x1="14" y1="11" x2="14" y2="17"/>
              </svg>
            </button>
        }
      </div>
    )
  }
}

export default Buttons