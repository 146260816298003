import React, {Component} from 'react'
import {createComment, editComment, deleteComment} from '../../../assets/axios/comments'
import moment from 'moment'
import Buttons from './FormElements/Buttons'

class CommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newComment: '',
      editingComment: '',
      editingCommentId: null,
      comments: this.props.comment.reverse()
    }

    this.changeComment = this.changeComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.notChangeComment = this.notChangeComment.bind(this);
  }

  addComment() {
    if (this.state.newComment) {
      createComment(this.props.id, this.state.newComment, this.props.update)
      this.setState({newComment: ''})
    }
  }

  updateComment(id) {
    if (this.state.editingComment) {
      editComment(id, this.state.editingComment, this.props.update)
      this.setState({editingCommentId: null, editingComment: ''})
    }
  }

  deleteComment(id) {
    const result = window.confirm('Вы действительно хотите удалить комментарий?')
    if (result) {
      deleteComment(id, this.props.update)
    }
  }

  notChangeComment() {
    this.setState({editingCommentId: null})
  }

  commentHandler({target}) {
    this.setState({newComment: target.value})
  }

  changeComment(id, text) {
    this.setState({editingCommentId: id, editingComment: text})
  }

  changeCommentHandler({target}) {
    this.setState({editingComment: target.value})
  }

  componentDidUpdate(prevProps) {
    if (this.props.comment !== prevProps.comment) {
      this.setState({comments: this.props.comment.reverse()})
    }
  }

  render() {
    return (
      <div className={'product-component col-lg-6 col-md-12'}>
        <h1 className={'product-title-main'}>Примечания</h1>
        <input className={'form-control product-textarea'}
                  onChange={(e) => this.commentHandler(e)}
                  value={this.state.newComment}/>
        <button className={'btn btn-outline-info'} onClick={() => this.addComment()}>Добавить</button>
        <div>
          {this.state.comments.map(item => {
            let date = moment(item.time).format('DD.MM.YYYY HH:mm')
            return (
              <div key={item.id} className={'comment'}>
                <div className={'comment-wrapper'}>
                  <div className={'comment-information'}>
                    <h1 className={'comment-admin'}>{item.admin.name}</h1>
                    <span className={'comment-time'}>{date}</span>
                  </div>
                  {this.state.editingCommentId === item.id
                    ? <input value={this.state.editingComment}
                             onChange={(e) => this.changeCommentHandler(e)}
                             className={'comment-textarea'}
                             autoFocus
                    />
                    : <p className={'comment-text'}>{item.text}</p>
                  }
                </div>
                <Buttons editId={this.state.editingCommentId}
                         item={item}
                         updateComment={this.updateComment}
                         changeComment={this.changeComment}
                         deleteComment={this.deleteComment}
                         notChangeComment={this.notChangeComment}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default CommentForm