import React, {useState, Fragment, useEffect} from "react";
import ThComponent from "./ThComponent";
import TdComponent from "./TdComponent";

const CustomTable = (props) => {
    const [columns, setColumns] = useState(props.columns)
    const [data, setData] = useState(props.data)

    useEffect(() => {
        props.updateState(props => {
            return {
                data: props.data = data,
                columns: props.columns = columns,
            }

        })
    }, [data,columns])

    const addColumns = () => {
        let lastCol = columns.length
        setColumns(prev => [...prev, {header: `Column ${lastCol + 1}`, accessor: `col${lastCol + 1}`}])

        let newData = data.map(item => {
            let lastRow = Object.keys(item).length
            item[`col${lastRow + 1}`] = ''
            return item
        })
        setData(newData)
    }

    const deleteColumns = () => {
        let lastColumn = columns[columns.length - 1]

        columns.splice(columns.length - 1, 1)
        let updateData = data.map(item => {
            delete item[lastColumn.accessor]
            return item
        })
        setData(updateData)
    }

    const addRow = () => {
        let lastRow = Object.keys(data[0]).length
        let counter = 0
        let newRow = {}

        while (counter < lastRow) {
            newRow[`col${counter + 1}`] = ''
            counter++
        }
        setData(prev => [...prev, newRow])
    }

    const deleteRow = () => {
        let updatedData = data.splice(0,data.length - 1)
        setData(updatedData)
    }

    const updateDataState = (index, column, name) => {
        let updatedData = data.map((item, i) => {
            if (index === i) {
                item[column] = name
            }
            return item
        })
        setData(updatedData)
    }

    const updateColumnsState = (name, accessor) => {
        let updatedColumns = columns.map(item => {
            if (item.accessor === accessor) {
                item.header = name
            }
            return item
        })
        setColumns(updatedColumns)
    }

    return (
            <div style={{position:'relative'}}>
                <div style={{overflow:'auto'}}>
                    <table>
                        <thead>
                        <tr>
                            {columns.map(title => {
                                return (
                                    <Fragment key={title.accessor}>
                                        <ThComponent title={title.header} accessor={title.accessor} update={updateColumnsState}/>
                                    </Fragment>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item,i) => {
                            return(
                                <tr key={i}>
                                    {columns.map(column => {
                                        return(
                                            <Fragment key={column.accessor}>
                                                <TdComponent title={item[column.accessor]} accessor={column.accessor} index={i} update={updateDataState}/>
                                            </Fragment>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className={'table__button table__button_col-plus'} onClick={addColumns}>+</button>
                    <button className={'table__button table__button_col-min'} onClick={deleteColumns}>&ndash;</button>
                    <button className={'table__button table__button_row-plus'} onClick={addRow}>+</button>
                    <button className={'table__button table__button_row-min'} onClick={deleteRow}>&ndash;</button>
                </div>
            </div>
    )
}
export default CustomTable