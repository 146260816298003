import React from 'react'
import {useEditor, Element} from '@craftjs/core'
import {Text} from './Text'
import {Button} from './Button'
import {Container} from './Container'
import {Image} from "./Image";
import {Divider} from "./Divider";
import {Video} from './Video'
import {Table} from './Table'
import {Carousel} from "./Carousel";

export const ToolBar = () => {
    const {connectors} = useEditor()
    return (
        <div className={'toolbar-component__wrapper card'}>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Text/>)}
            >Текст
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Button/>)}
            >Кнопка
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Element is={Container} canvas/>)}
            >Контейнер
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Image/>)}
            >Картинка
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Divider/>)}
            >Разделитель
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Video/>)}
            >Видео
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Table/>)}
            >Таблицы
            </button>
            <button
                className={'btn toolbar-component__button'}
                ref={ref => connectors.create(ref, <Carousel/>)}
            >Карусель
            </button>
        </div>
    )
}