import pdfIcon from "../images/fileIcons/pdf.png";
import fileIcon from "../images/fileIcons/unknown.png";

export function shortenString(string, limit) {
    let [ name, extension ] = string.split(".");

    name = name.length > limit ?
        ( name.slice(0, limit - 2) + "..." ) :
        name;

    return name + (extension ? `.${extension}` : '');
}

export function throttle(f, ms) {
    let isAvailable = true;
    let savedFunc = {context: null, arguments: null};

    function wrapper(...args) {
        if (isAvailable) {
            f.apply(this, args);
            isAvailable = false;

            setTimeout(function () {
                isAvailable = true;

                if (savedFunc.arguments) {
                    f.apply(savedFunc.context, savedFunc.arguments);
                    savedFunc.context = savedFunc.arguments = null;
                }
            }, ms);
        } else {
            savedFunc.context = this;
            savedFunc.arguments = args;
        }
    }

    return wrapper;
}

export function formatDate(dateString) {
    let date = new Date(dateString);

    let year = date.getFullYear();

    let month = date.getMonth()+1;
    month = month < 10 ? ("0" + month) : month;

    let day = date.getDate();
    day = day < 10 ? ("0" + day) : day;

    let hour = date.getHours();
    hour = hour < 10 ? ("0" + hour) : hour;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? ("0" + minutes) : minutes;

    let seconds = date.getSeconds();
    seconds = seconds < 10 ? ("0" + seconds) : seconds;

    return `${day}.${month}.${year} ${hour}:${minutes}:${seconds}`;
}

export function getPaymentLink(data) {
    return "https://auth.robokassa.ru/Merchant/Index.aspx?" +
        "MerchantLogin=omegabot" +
        "&InvId=" + data.id +
        "&IsTest=" + 0 +
        "&Culture=ru" +
        "&Encoding=utf-8" +
        "&OutSum=" + data.cost +
        "&SignatureValue=" + data.sign;
}

export function makeFileIcon(type) {
    switch (true) {
        case type.includes('pdf'):
            return pdfIcon;
        default:
            return  fileIcon;
    }
}