import axios from 'axios';
import {ORDER_DETAIL, ORDER_STATUS, ORDER_STATUSES, ORDERS} from "../../constants/axiosURLs";

// import data, {statuses} from "../data/orders";

export function getOrdersList(thenFunc, catchFunc) {
    // let fakeResponse = {data: data};
    // thenFunc(fakeResponse);

    axios.get(ORDERS, { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function getOrderDetail(orderId, thenFunc, catchFunc) {
    // let fakeResponse = {data:
    //         data.find( item => ''+item.id === orderId )};
    //
    // thenFunc(fakeResponse);

    axios.get(ORDER_DETAIL(orderId), { withCredentials: true })
        .then(thenFunc)
        .catch(catchFunc);
}

export function deleteOrder(orderId, thenFunc) {
    // eslint-disable-next-line no-restricted-globals
    let isDelete = confirm("Вы уверены, что хотите удалить заказ?");

    if (isDelete) {
        let catchFunc = () =>
            alert("Не удалось удалить заказ");

        axios.delete(ORDER_DETAIL(orderId), { withCredentials: true })
            .then(thenFunc)
            .catch(catchFunc);
    }
}

export function getOrderStatuses(thenFunc) {
    // let fakeResponse = {data: statuses};
    // thenFunc(fakeResponse);

    axios.get(ORDER_STATUSES, { withCredentials: true })
        .then(thenFunc)
        .catch(() => alert("Не удалось загрузить статусы заказа"));
}

export function changeOrderStatus(orderId, statusId, thenFunc) {
    // thenFunc();

    axios.put( ORDER_STATUS(orderId,statusId), {}, { withCredentials: true } )
        .then(thenFunc)
        .catch(() => alert("Не удалось изменить статус заказа"));
}