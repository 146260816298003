import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import {EMPTY_ITEM_IN_PRODUCT} from "../../../constants/other";
import {connect} from "react-redux";
import {getCategoriesList} from "../../../assets/axios/categories";
import {getSuppliersList} from '../../../assets/axios/suppliers'
import {getProductDetail} from "../../../assets/axios/products";
import MyDropzone from "../../common/dropzone";
import ProductTabs from "../product-detail/ProductTabs";
import ProductComplectationForm from "./ProductComplectationForm";
import ProductSpecificationForm from "./ProductSpecificationForm";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ExistingProductPictures from "./ExistingProductPictures";
import {addProduct, editProduct} from "../../../assets/axios/products";
import ExistingProductFiles from "./ExistingProductFiles";

class ProductForm extends Component {
    isEdit = !!this.props.productId;

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            category: null,
            categoriesList: [],
            price: '',
            stock: '',
            size: {length: '', height: '', width: '', weight: ''},
            videoLink: '',
            pictures: [],
            newPictures: [],
            shortDetails: '',
            fullDetails: '',
            complectationItems: [Object.assign({}, EMPTY_ITEM_IN_PRODUCT)],
            characteristicItems: [Object.assign({}, EMPTY_ITEM_IN_PRODUCT)],
            files: [],
            newFiles: [],
            suppliersList: [],
            supplier: null,
            primeCost: null
        };

        this.changeName = this.changeName.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeSupplier = this.changeSupplier.bind(this);
        this.changePrice = this.changePrice.bind(this);
        this.changePrimeCost = this.changePrimeCost.bind(this);
        this.changeStock = this.changeStock.bind(this);
        this.changeSize = this.changeSize.bind(this);
        this.changeVideoLink = this.changeVideoLink.bind(this);
        this.changeShortDetails = this.changeShortDetails.bind(this);
        this.changeFullDetails = this.changeFullDetails.bind(this);
        this.changeComplectationItem = this.changeComplectationItem.bind(this);
        this.addComplectationItem = this.addComplectationItem.bind(this);
        this.changeCharacteristicItem = this.changeCharacteristicItem.bind(this);
        this.addCharacteristicItem = this.addCharacteristicItem.bind(this);
        this.uploadNewPicture = this.uploadNewPicture.bind(this);
        this.deleteNewPicture = this.deleteNewPicture.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
        this.uploadNewFile = this.uploadNewFile.bind(this);
        this.deleteNewFile = this.deleteNewFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }

    componentDidMount() {
        getCategoriesList(
            response => this.setState({
                categoriesList: response.data
            }),
            () => alert("Не удалось загрузить список категорий")
        );

        getSuppliersList(
            response => this.setState({suppliersList: response.data}),
            () => alert('Не удалось загрузить список поставщиков')
        );

        if (this.isEdit) {
            getProductDetail(
                this.props.productId,
                (response) => {
                    console.log(response.data)
                    this.changeDetail(response.data)
                },
                () => {
                    alert("Не удалось загрузить информацию о продукте")
                });
        }
    }

    changeDetail(data) {
        let {
            name, price, stock, videoLink, pictures, files,
            shortDetails, fullDetails, complectationItems,
            characteristicItems, category, supplier, prime_cost
        } = data;

        this.setState({
            name, price, stock, videoLink, pictures, files,
            shortDetails, fullDetails, complectationItems,
            characteristicItems,
            primeCost: prime_cost,
            supplier: supplier ? supplier.id : supplier,
            category: category ? category.id : category,
            size: {
                length: data.length,
                height: data.height,
                width: data.width,
                weight: data.weight
            }
        });
    }

    changeName(event) {
        this.setState({
            name: event.target.value
        });
    }

    changeCategory(event) {
        this.setState({category: event.target.value});
    }

    changeSupplier(event) {
        console.log(event.target.value)
        if(event.target.value === 'Нет') {
            this.setState({supplier: null})
        } else {
            this.setState({supplier: event.target.value})
        }
    }

    changePrice(event) {
        this.setState({
            price: event.target.value
        });
    }

    changePrimeCost(event) {
        this.setState({
            primeCost: event.target.value === '' ? null : event.target.value
        })
    }

    changeStock(event) {
        this.setState({
            stock: event.target.value
        });
    }

    changeSize(field, value) {
        this.setState((state) => {
            state.size[field] = value;
            return {size: state.size};
        });
    }

    changeVideoLink(event) {
        this.setState({
            videoLink: event.target.value
        });
    }

    changeShortDetails(event) {
        this.setState({
            shortDetails: event.target.value
        });
    }

    changeFullDetails(value) {
        this.setState({
            fullDetails: value
        });
    }

    changeComplectationItem(index, field, value) {
        this.setState((state) => {
            state.complectationItems[index][field] = value;
            return {complectationItems: state.complectationItems}
        });
    }

    addComplectationItem() {
        this.setState((state) => {
            state.complectationItems
                .push(Object.assign({},
                    EMPTY_ITEM_IN_PRODUCT));

            return {complectationItems: state.complectationItems}
        });
    }

    changeCharacteristicItem(index, field, value) {
        this.setState((state) => {
            state.characteristicItems[index][field] = value;
            return {characteristicItems: state.characteristicItems}
        });
    }

    addCharacteristicItem() {
        this.setState((state) => {
            state.characteristicItems
                .push(Object.assign({},
                    EMPTY_ITEM_IN_PRODUCT));

            return {characteristicItems: state.characteristicItems}
        });
    }

    uploadNewPicture(file) {
        this.setState((state) => {
            state.newPictures.push(file);
            return {newPictures: state.newPictures};
        });
    }

    deleteNewPicture(file) {
        this.setState((state) => {
            let foundFile = state.newPictures
                .indexOf(file);

            state.newPictures.splice(foundFile, 1);

            return {newPictures: state.newPictures};
        });
    }

    deletePicture(pic) {
        this.setState((state) => {
            let foundPic = state.pictures.indexOf(pic);

            state.pictures.splice(foundPic, 1);

            return {pictures: state.pictures};
        });
    }

    uploadNewFile(file) {
        this.setState((state) => {
            state.newFiles.push(file);
            return {newFiles: state.newFiles};
        });
    }

    deleteNewFile(file) {
        this.setState((state) => {
            let foundFile = state.newFiles
                .indexOf(file);

            state.newFiles.splice(foundFile, 1);

            return {newFiles: state.newFiles};
        });
    }

    deleteFile(file) {
        this.setState((state) => {
            let foundFile = state.files.indexOf(file);

            state.files.splice(foundFile, 1);

            return {files: state.files};
        });
    }

    handleForm(event) {
        event.preventDefault();
        let isComplectation = this.state.complectationItems
            .some((item) => item.name && item.value);
        let isSpecification = this.state.characteristicItems
            .some((item) => item.name && item.value);

        if ((this.state.pictures.length
            || this.state.newPictures.length)
            && (isComplectation || isSpecification)) {

            this.isEdit
                ? editProduct(Object.assign({}, this.state, {id: this.props.productId}))
                : addProduct(this.state);
        } else {
            alert("Не заполнено поле Изображения или хотя бы одна пара полей в разделах Комплектация или Характеристики");
        }
    }

    render() {
        return (
            <>
                <Breadcrumb title={`${this.isEdit ? "Редактировать" : "Добавить"} товар`}/>
                <div className="container-fluid">
                    <form onSubmit={this.handleForm}>
                        <div className="row product-adding">
                            <div className="row product-adding">
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Основная информация</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="digital-add needs-validation">
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0"><span>*</span> Название
                                                        товара</label>
                                                    <input className="form-control"
                                                           type="text" required={true}
                                                           value={this.state.name}
                                                           onChange={this.changeName}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Краткое описание</label>
                                                    <input className="form-control"
                                                           type="text" required={false}
                                                           value={this.state.shortDetails}
                                                           onChange={this.changeShortDetails}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Категория</label>
                                                    <select className="form-control"
                                                            value={this.state.category}
                                                            onChange={this.changeCategory}>
                                                        <option value={null}>Нет</option>
                                                        {
                                                            this.state.categoriesList.map(category =>
                                                                <option value={category.id} key={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label
                                                            className="col-form-label pt-0"><span>*</span> Цена</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.price}
                                                               onChange={this.changePrice}/>
                                                    </div>

                                                    <div className="form-group col-6">
                                                        <label className="col-form-label pt-0">Себестоимость</label>
                                                        <input className="form-control"
                                                               type="number"
                                                               value={this.state.primeCost === null ? '' : this.state.primeCost}
                                                               onChange={this.changePrimeCost}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={'row'}>
                                                    <div className="form-group col-6">
                                                        <label className="col-form-label pt-0">Поставщик</label>
                                                        <select className="form-control"
                                                                value={this.state.supplier}
                                                                onChange={this.changeSupplier}>
                                                            <option value={null}>Нет</option>
                                                            {
                                                                this.state.suppliersList.map(supplier =>
                                                                    <option value={supplier.id} key={supplier.id}>
                                                                        {supplier.name}
                                                                    </option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-6">
                                                        <label className="col-form-label pt-0"><span>*</span> Количество
                                                            на складе</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.stock}
                                                               onChange={this.changeStock}/>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-3">
                                                        <label className="col-form-label pt-0"><span>*</span> Д,
                                                            см</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.size.length}
                                                               onChange={(e) => this.changeSize("length", e.target.value)}/>
                                                    </div>

                                                    <div className="form-group col-3">
                                                        <label className="col-form-label pt-0"><span>*</span> Ш,
                                                            см</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.size.width}
                                                               onChange={(e) => this.changeSize("width", e.target.value)}/>
                                                    </div>

                                                    <div className="form-group col-3">
                                                        <label className="col-form-label pt-0"><span>*</span> В,
                                                            см</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.size.height}
                                                               onChange={(e) => this.changeSize("height", e.target.value)}/>
                                                    </div>

                                                    <div className="form-group col-3">
                                                        <label className="col-form-label pt-0"><span>*</span> Вес,
                                                            кг</label>
                                                        <input className="form-control"
                                                               type="number" required={true}
                                                               value={this.state.size.weight}
                                                               onChange={(e) => this.changeSize("weight", e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-form-label pt-0"><span>*</span> Изображения
                                                        товаров</label>
                                                    <ExistingProductPictures pictures={this.state.pictures}
                                                                             onDelete={this.deletePicture}/>
                                                    <MyDropzone onUpload={this.uploadNewPicture}
                                                                onDelete={this.deleteNewPicture}
                                                                accept={"image/*"}/>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Дополнительные файлы</label>
                                                    <ExistingProductFiles files={this.state.files}
                                                                          onDelete={this.deleteFile}/>
                                                    <MyDropzone onUpload={this.uploadNewFile}
                                                                onDelete={this.deleteNewFile}
                                                                accept={"*"}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Вкладки</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="digital-add needs-validation">
                                                <ProductTabs tabs={[
                                                    {
                                                        name: "Комплектация",
                                                        content: <ProductComplectationForm
                                                            complectationItems={this.state.complectationItems}
                                                            onChange={this.changeComplectationItem}
                                                            onAdd={this.addComplectationItem}/>
                                                    },
                                                    {
                                                        name: "Характеристики",
                                                        content: <ProductSpecificationForm
                                                            characteristicItems={this.state.characteristicItems}
                                                            onChange={this.changeCharacteristicItem}
                                                            onAdd={this.addCharacteristicItem}/>
                                                    }
                                                ]}/>
                                            </div>
                                            <br/>
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">Ссылка на видео (youtube)</label>
                                                <input className="form-control"
                                                       type="text"
                                                       value={this.state.videoLink}
                                                       onChange={this.changeVideoLink}/>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-form-label pt-0">Полное описание</label>
                                                <ReactQuill value={this.state.fullDetails}
                                                            onChange={this.changeFullDetails}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 text-center">
                                    <button type="submit" className="btn btn-primary obot-form-button">
                                        {this.isEdit ? 'Сохранить' : 'Добавить товар'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        productId: ownProps.match.params.id || null,
    }
}

export default connect(mapStateToProps)(ProductForm);