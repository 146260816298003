import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import Datatable from "../common/datatable";
import {newsColumns} from "../../assets/data/newsData";
import {loadNewsList} from '../../redux/actions/newsAction'
import {connect} from "react-redux";
import {deleteNews} from "../../assets/axios/news";

class ListPages extends Component {
    constructor(props) {
        super(props);

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onGetNewsClick = this.onGetNewsClick.bind(this)
    }

    componentDidMount() {
        this.props.loadNewsList();
    }

    onDeleteClick() {
        let self = this;
        return function(newsId) {
            deleteNews(newsId, () => self.props.loadNewsList());
        }
    }

    onGetNewsClick() {
        return function(newsId) {
            document.location.href = `/pages/edit-page/${newsId}`
        }
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title="Список новостей" parent="Pages" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div id="batchDelete" className="category-table order-table ">
                                        <Datatable
                                            multiSelectOption={false}
                                            columns={newsColumns(this.onDeleteClick(), this.onGetNewsClick())}
                                            myData={this.props.list}
                                            pageSize={10}
                                            pagination={true}
                                            class="-striped -highlight"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default connect(state => state.news, { loadNewsList }) (ListPages)
