import {INITIAL_STORE_STATE} from "../StoreInit";
import productsReducer from "./products";
import userReducer from "./user";
import ordersReducer from "./orders";
import categoriesReducer from "./categories";
import newsReducer from './newsReducer'

export default function mainReducer(state = INITIAL_STORE_STATE, action) {
    return {
        products: productsReducer(state.products, action),
        categories: categoriesReducer(state.categories, action),
        user: userReducer(state.user, action),
        orders: ordersReducer(state.orders, action),
        news: newsReducer(state.news,action)
    };
}