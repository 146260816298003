import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';

export class ProductTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        }
    }

    changeTab(i) {
        this.setState({activeTab: i});
    }

    render() {
        let { tabs } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <Tabs className="obot tab-content nav-material" selectedIndex={this.state.activeTab}>
                        <TabList className="nav nav-tabs nav-material">
                            {
                                tabs.map((tab, i) =>
                                    <Tab className="nav-item" key={i}
                                         onClick={() => this.changeTab(i)}>
                                    <span className={"nav-link " +
                                    (i === this.state.activeTab ? "active" : "")}>

                                        {tab.name}
                                    </span>
                                        <div className="material-border"></div>
                                    </Tab>
                                )
                            }
                        </TabList>

                        {
                            tabs.map((tab,i) =>
                                <TabPanel key={i}>
                                    <div className={"obot-tab-content"}>
                                        {tab.content}
                                    </div>
                                </TabPanel>
                            )
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default ProductTabs