import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {connect} from "react-redux";
import {getProductDetail} from "../../../assets/axios/products";

import emptyProduct from '../../../assets/images/image-stub.jpg';

import {SYMBOL, THEME_COLOR} from "../../../constants/other";
import ProductTabs from "./ProductTabs";
import {makeProductTabs} from "./tab-contents";
import Loader from "react-loader-spinner";
import ProductFiles from "./ProductFiles";


export class Product_detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nav1: null,
            nav2: null,
            detail: null
        }

        this.changeDetail = this.changeDetail.bind(this);
    }

    changeDetail(response) {
        this.setState({detail: response.data});
    }

    componentDidMount() {
        getProductDetail(
            this.props.productId,
            this.changeDetail,
            () => alert("Не удалось загрузить информацию о продукте"));

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const {detail} = this.state;
        console.log(detail)
        const pictures = detail && detail.pictures && detail.pictures.length
            ? detail.pictures
            : [1, 2, 3];

        return (
            this.state.detail
                ?
                <Fragment>
                    <Breadcrumb title="Детали товара"/>

                    <div className="container-fluid">
                        <div className="card">
                            <div className="row product-page-main card-body">
                                <div className="col-xl-4">
                                    <Slider asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}
                                            className="product-slider">
                                        {
                                            pictures.map((item, i) =>
                                                <div className="item" key={item.id || i}>
                                                    <div className="img-wrapper">
                                                        <div className="lable-block">
                                                            {(detail.new) ? <span className="lable3">new</span> : ''}
                                                            {(detail.discount) ?
                                                                <span className="lable4">on sale</span> : ''}
                                                        </div>
                                                        <div className="front">
                                                            <img className="img-fluid"
                                                                 src={item.url || emptyProduct}
                                                                 alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Slider>

                                    <Slider
                                        asNavFor={this.state.nav1}
                                        ref={slider => (this.slider2 = slider)}
                                        slidesToShow={pictures.length <= 3 ? pictures.length : 3}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        className="small-slick">
                                        {
                                            pictures.map((item, i) =>
                                                <div className="item" key={item.id || i}>
                                                    <img className="img-fluid"
                                                         src={item.url || emptyProduct}
                                                         alt=""/>
                                                </div>
                                            )
                                        }

                                    </Slider>
                                </div>
                                <div className="col-xl-8">
                                    <div className="product-page-details product-right mb-0">
                                        <h2>{detail.name}</h2>
                                        <hr/>
                                        <h6 className="product-title">Категория</h6>
                                        <p>{detail.category ? detail.category.name : 'Нет'}</p>
                                        <hr/>
                                        <h6 className="product-title">Описание</h6>
                                        <p>{detail.shortDetails}</p>
                                        <div className="product-price digits mt-2">
                                            <h3>{
                                                detail.discount
                                                    ? <div>
                                                        {Math.round(detail.price * (100 - detail.discount) / 100)} {SYMBOL}
                                                        <del>{detail.price} {SYMBOL}</del>
                                                    </div>
                                                    : <div>{detail.price} {SYMBOL}</div>
                                            }</h3>
                                            {!detail.prime_cost
                                                ? ''
                                                : <p>Себест. {detail.prime_cost} {SYMBOL}</p>

                                            }
                                        </div>
                                        <hr/>
                                        <h6 className="product-title">Количество на складе</h6>
                                        <p>{detail.stock} шт.</p>
                                        <hr/>
                                        {!detail.supplier
                                            ? ''
                                            : <>
                                                <h6 className="product-title">Поставщик</h6>
                                                <p>{!detail.supplier ? '' : detail.supplier.name}</p>
                                                <hr/>
                                            </>
                                        }
                                        <h6 className="product-title">Параметры (Д х В х Ш, вес)</h6>
                                        <p>{detail.length}см x {detail.height}см
                                            x {detail.width}см, {detail.weight}кг</p>
                                        <hr/>

                                        <ProductTabs tabs={makeProductTabs(detail)}/>
                                        <ProductFiles files={detail.files}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
                : <Loader type="Hearts"
                          width={50} height={50}
                          color={THEME_COLOR}
                          className={"obot-loader"}/>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        productId: ownProps.match.params.id,
    }
}

export default connect(mapStateToProps)(Product_detail);