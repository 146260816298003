import React, {useState} from "react";

const TdComponent = ({title, accessor,index, update}) => {
    const [name, setName] = useState(title)
    const [edit,setEdit] = useState(false)

    const handler = ({target}) => {
        setName(target.value)
    }

    const editController = () => {
        if(edit) {
            update(index,accessor, name)
        }
        setEdit(prev => !prev)
    }
    return (
        <td onDoubleClick={editController}>
            {
                edit
                    ? <input type="text"
                             value={name}
                             onChange={e => handler(e)}
                             className={'input'}
                             onBlur={editController} autoFocus/>
                    : name
            }
        </td>
    )
}

export default TdComponent