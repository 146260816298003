import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

import {makeFileIcon, shortenString} from "../../assets/js/functions";
import {THEME_COLOR} from "../../constants/other";
import {Trash2, UploadCloud} from "react-feather";

const Layout = ({input, previews, dropzoneProps, files, extra: {maxFiles}}) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>
                {files.length < maxFiles && input}
            </div>
        </div>
    )
}

const FileInput = () => {
    return (
        <div className={"file-input"}>
            <UploadCloud/>
            <div>Нажмите для загрузки или перетащите файлы</div>
        </div>
    )
}

const AddMoreFile = (obj) => {
    return (
        <div className={"file-input"}>
            Добавить файлы
        </div>
    )
}

const MyUploader = (props) => {

    const Preview = ({fileWithMeta}) => {
        const {name, percent, previewUrl} = fileWithMeta.meta;
        const file = fileWithMeta.file;
        const isImage = file.type.includes('image');

        let loaderTheme = {
            active: {color: THEME_COLOR, trailColor: "#fff"},
        };

        return (
            <div className={"obot file-preview-container"}>
                <div className={"file-name"}>
                    <img src={isImage ? previewUrl : makeFileIcon(file.type)}
                         className={isImage ? "" : "file-icon"}
                         alt={name}/>
                    <span>{shortenString(name, 25)}</span>
                </div>

                <div className={"file-state"}>{
                    percent < 100
                        ? <Progress type="circle" percent={Math.round(percent)}
                                    width={40} theme={loaderTheme}/>

                        : <span className={"obot-primary-color"}
                                onClick={() => {
                                    fileWithMeta.remove();
                                    props.onDelete(file);
                                }}>
                            <Trash2/>
                        </span>
                }</div>
            </div>
        )
    }

    const getUploadParams = () => {
        return {url: 'https://httpbin.org/post'}
    };

    const handleChangeStatus = ({file}, status) => {
        if (status === "done") props.onUpload(file)
    };

    return (
        <div className="dropzone obot">
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                PreviewComponent={Preview}
                inputContent={FileInput}
                inputWithFilesContent={AddMoreFile}
                LayoutComponent={Layout}
                disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
                accept={props.accept}
            />
        </div>

    )
}

export default MyUploader