import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable'
import {connect} from "react-redux";
import {loadOrdersList} from "../../redux/actions/orders";
import {ORDERS} from "../../constants/axiosURLs";
import SearchHeader from "../common/header_components/searchHeader";
import {orderColumns} from "../../assets/data/orders";
import {deleteOrder} from "../../assets/axios/orders";

export class Orders extends Component {
    constructor(props) {
        super(props);

        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        this.props.loadOrdersList();
    }

    onDeleteClick() {
        let self = this;
        return function(orderId) {
            deleteOrder(orderId, () => self.props.loadOrdersList());
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Список заказов" />
                <div className="container-fluid">
                    <SearchHeader url={ORDERS} callback={this.props.loadOrdersList} />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body order-datatable">
                                    <Datatable
                                        multiSelectOption={false}
                                        columns={ orderColumns( this.onDeleteClick() ) }
                                        myData={this.props.list}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default connect(state => state.orders, { loadOrdersList }) (Orders)
