import * as types from "../../constants/actionsTypes/newsActionTypes";
import {getNewsList} from "../../assets/axios/news";

function updateNewsList(list) {
    return {
        type: types.UPDATE_NEWS_LIST,
        value: list
    };
}

export function loadNewsList(list = null) {
    if (list) {
        return (dispatch) => {
            dispatch( updateNewsList(list) );
        }
    } else {
        return (dispatch) => {
            getNewsList(
                (response) => dispatch( updateNewsList(response.data) ),
                () => {
                    alert("Не удалось загрузить список новостей");
                    dispatch( updateNewsList([]) );
                });

        }
    }
}